import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "1fr 2fr",
    padding: "3rem 1.5rem",
    color: "#fff",
    "@media (max-width:1024px)": {
      gridTemplateColumns: "1fr",
    },
  },
  coral: {
    backgroundColor: "#FF997B",
  },
  pink: {
    backgroundColor: "#EBA7AD",
  },
  title: {
    marginBottom: "2rem",
  },
  paragraph: {
    margin: "1rem 0",
    letterSpacing: "0.75px",
    lineHeight: 1.5,
    fontSize: "1rem",
  },
});
