import swal from "sweetalert";
import { useStyles } from "./styles";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextfieldWrapper } from "./TextfieldWrapper";
import { ButtonWrapper } from "./Button";
import "./styles.css";
import emailjs from "emailjs-com";

export const MyForm = () => {
  const styles = useStyles();
  const initial_form_state = {
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  };
  const form_validation = Yup.object().shape({
    name: Yup.string().required("Ingresa tu nombre"),
    email: Yup.string()
      .email("Correo electrónico errado")
      .required("Ingresa tu correo electrónico"),
    subject: Yup.string().required("Ingresa el asunto"),
    phone: Yup.number()
      .integer()
      .typeError("Ingresa un número telefónico válido")
      .required("Ingresa tu número telefónico"),
    message: Yup.string()
      .min(10, "Tu mensaje debe contener al menos 10 caracteres")
      .required("Ingresa tu mensaje"),
  });

  return (
    <div>
      <h5 className={styles.title}>Contáctanos</h5>
      <Formik
        initialValues={{ ...initial_form_state }}
        validationSchema={form_validation}
        onSubmit={(values, onSubmitProps) => {
          emailjs
            .send("service_7yx227h", "template_tmtrumb", values, "user_3pXVo6Wm89D9p6YgwzqAG")
            .then(
              () => {
                swal("¡Mensaje enviado!", "Pronto nos pondremos en contacto contigo.", "success");
                onSubmitProps.resetForm();
              },
              (err) => {
                console.error(err);
                console.log(values);
              }
            );
        }}
      >
        <Form className={styles.container}>
          <TextfieldWrapper name="name" label="Nombre" />
          <TextfieldWrapper name="email" label="Correo electrónico" />
          <TextfieldWrapper name="subject" label="Asunto" />
          <TextfieldWrapper name="phone" label="Número telefónico" />
          <TextfieldWrapper
            className={styles.textarea}
            name="message"
            label="Mensaje"
            rows={4}
            multiline
          />
          <ButtonWrapper>Enviar</ButtonWrapper>
        </Form>
      </Formik>
    </div>
  );
};
