import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    position: "relative",
    width: "100%",
  },
  title: {
    color: "#0B1648",
    fontSize: "1.25rem",
    marginBottom: "1.5rem",
  },
  paragraph: {
    margin: "2rem 0",
    letterSpacing: "0.75px",
    lineHeight: 1.5,
  },
  history: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "100%",
    "@media(max-width:1024px)": {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr 1fr",
    },
  },
  "history-content": {
    padding: "3rem 1.5rem",
    fontSize: "1rem",
  },
  "history-color": {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F86354",
    display: "flex",
  },
  "history-image": {
    width: "100%",
    maxWidth: 352,
    maxHeight: 352,
    height: "100%",
  },
  mission: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "100%",
    "@media(max-width:1024px)": {
      gridTemplateColumns: "1fr",
    },
  },
  "mission-color": {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    backgroundColor: "#7CC3DA",
  },
  "mission-image": {
    width: "100%",
    maxWidth: 440,
    maxHeight: 440,
    height: "100%",
    "@media(max-width:1024px)": {
      display: "none",
    },
  },
});
