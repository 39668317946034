import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    width: "100%",
    padding: "3rem 1.5rem",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr",
    "@media(max-width:1024px)": {
      gridTemplateColumns: "1fr",
    },
  },
  title: {
    color: "#0B1648",
    fontSize: "1.25rem",
    marginBottom: "1.5rem",
  },
  paragraph: {
    margin: "2rem 0",
    letterSpacing: "0.75px",
    lineHeight: 1.5,
    fontSize: "1rem",
  },
  img: {
    width: "100%",
  },
});
