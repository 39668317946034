export const sliderContent = [
  {
    header: "En el Gimnasio Claudio Monteverdi",
    title: "Educamos con amor",
    paragraph: "Contribuyendo a la construcción de un mundo mejor.",
    button: "Contáctanos",
    style: ["primary", "primary-title", "primary-button"],
    image:
      "https://firebasestorage.googleapis.com/v0/b/gimnasio-claudio-monteve-4f383.appspot.com/o/images%2Fimage-1%201.png?alt=media&token=a1a61c1b-909f-40a5-b057-748911413037",
    alt: "Kid watching a globus terraqueus",
    push: "/contact",
  },
  {
    header: "En el Gimnasio Claudio Monteverdi",
    title: "Reconocemos las múltiples inteligencias",
    paragraph: "Permitiendo a los niños ser ellos mismos y desarrollar sus talentos.",
    button: "Leer más",
    style: ["secondary", "secondary-title", "secondary-button"],
    image:
      "https://firebasestorage.googleapis.com/v0/b/gimnasio-claudio-monteve-4f383.appspot.com/o/images%2Fimage-2.png?alt=media&token=b9b6f43d-a0c1-43c5-a5f4-3e803bbbead5",
    alt: "Kid jumping with papers by her side",
    push: "/projects",
  },
  {
    header: "En el Gimnasio Claudio Monteverdi",
    title: "Hacemos equipo con los papás",
    paragraph: "Porque juntos velamos por la formación integral de nuestros niños.",
    button: "Sobre nosotros",
    style: ["tertiary", "tertiary-title", "tertiary-button"],
    image:
      "https://firebasestorage.googleapis.com/v0/b/gimnasio-claudio-monteve-4f383.appspot.com/o/images%2Fimage-3.png?alt=media&token=65d6cb77-7d13-4925-bfbe-7a696ac4615f",
    alt: "Kid with painted hands",
    push: "/about",
  },
];
