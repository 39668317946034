export const cards = [
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/gimnasio-claudio-monteve-4f383.appspot.com/o/images%2Fenviroment.png?alt=media&token=e5375bc2-d769-4771-bda1-4d34ed0ecd78",
    alt: "Monteverdi cuida su entorno",
    title: "Monteverdi cuida su entorno",
    text: "Es un proyecto que sensibiliza a toda la comunidad frente al cuidado de nuestro medio ambiente. ",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/gimnasio-claudio-monteve-4f383.appspot.com/o/images%2Fbrain.png?alt=media&token=87bcf49d-6073-4fbd-b325-3dd1e4105029",
    alt: "Brain Tools",
    title: "Brain Tools",
    text: "Es un proyecto mediante el cual identificamos y fortalecemos el desarrollo de las múltiples inteligencias de los niños y niñas.",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/gimnasio-claudio-monteve-4f383.appspot.com/o/images%2Fhealthy.png?alt=media&token=55664cc9-cf2f-45c8-bf20-18cb0bfe78f5",
    alt: "Una escuela saludable para una vida felíz",
    title: "Una escuela saludable para una vida felíz",
    text: "Es un proyecto que empodera a los padres de familia en la creación de entornos saludables a través de diferentes encuentros y actividades.",
  },
];
