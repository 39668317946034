import { Card, CardContent } from "@material-ui/core";
import { cards } from "./cards";
import { useStyles } from "./styles";

export const Cards = () => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      {cards.map((item, index) => (
        <Card key={index} className={styles.card}>
          <CardContent className={styles.cardContent}>
            <img className={styles.image} src={item.image} alt={item.alt} />
            <h5 className={styles.title}>{item.title}</h5>
            <p className={styles.text}>{item.text}</p>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};
