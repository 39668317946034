import { Banner } from "../../components/Banner";
import { Cards } from "../../components/Cards";

export const Projects = () => {
  return (
    <>
      <Banner
        bg="coral"
        title="Nuestros proyectos"
        paragraph1="A través de los proyectos, el Gimnasio Claudio Monteverdi fortalece y complementa los procesos académicos de los estudiantes en sus diferentes dimensiones."
        paragraph2="Facilitamos el aprendizaje colaborativo para que los estudiantes puedan profundizar en temas de la vida real."
      />
      <Cards />
    </>
  );
};
