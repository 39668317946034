import { useStyles } from "./styles.jsx";
import { Toolbar } from "@material-ui/core";
import {
  footerInformation,
  footerSocial,
  footerSchedule,
  footerCopyright,
} from "./footerContent.js";
import logo from "./logo.svg";

export const Footer = () => {
  const styles = useStyles();
  return (
    <Toolbar className={styles["footer"]}>
      <div className={styles["logo"]}>
        <img src={logo} alt="Escudo Gimnasio Claudio Monteverdi" />
        <h1 className={styles["logo-text"]}>Gimnasio Claudio Monteverdi</h1>
      </div>
      <h3 className={styles["info-title"]}>Información</h3>
      <section className={styles["info"]}>
        {footerInformation.map((item, i) => (
          <div className={styles["item"]} onClick={() => window.open(item.link)} key={i}>
            {item.icon}
            <p>{item.content}</p>
          </div>
        ))}
      </section>
      <h3 className={styles["social-title"]}>Redes sociales</h3>
      <section className={styles["social"]}>
        {footerSocial.map((item, i) => (
          <div className={styles["item"]} onClick={() => window.open(item.link)} key={i}>
            {item.icon}
            <p>{item.content}</p>
          </div>
        ))}
      </section>
      <h3 className={styles["schedule-title"]}>Horarios de atención</h3>
      <section className={styles["schedule"]}>
        {footerSchedule.map((item, i) => (
          <div className={styles["schedule-item"]} key={i}>
            <span>{item.date}</span>
            <p>{item.time}</p>
          </div>
        ))}
      </section>
      <section className={styles["copyright"]}>
        {footerCopyright.map((item, i) => (
          <p className={styles[item.className]} onClick={() => window.open(item.link)} key={i}>
            {item.content}
          </p>
        ))}
      </section>
    </Toolbar>
  );
};
