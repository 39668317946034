import { useStyles } from "./styles";

export const Banner = (props) => {
  const styles = useStyles();
  return (
    <div className={`${styles.container} ${styles[props.bg]}`}>
      <div>
        <h2 className={styles.title}>{props.title}</h2>
        <p className={styles.paragraph}>{props.paragraph1}</p>
        <p className={styles.paragraph}>{props.paragraph2}</p>
      </div>
    </div>
  );
};
