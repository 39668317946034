import { Banner } from "../../components/Banner";
import { Options } from "../../components/Options";

export const Homeschool = () => {
  return (
    <>
      <Banner
        bg="pink"
        title="Homeschool"
        paragraph1="El proyecto “At Home”, ha sido desarrollado por el Gimnasio Claudio Monteverdi con el objetivo de orientar, asesorar y acompañar a las familias que han optado por la educación en casa en los diferentes procesos de aprendizaje de sus hijos."
        paragraph2="Profesionales en educación y psicología aplicada a la educación se han unido para crear una oportunidad que enriquece procesos de educación alternativa de estudiantes que no asisten a la escuela regular."
      />
      <Options />
    </>
  );
};
