import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  footer: {
    backgroundColor: "#0b1648",
    color: "#fff",
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr) 15rem",
    gridTemplateRows: "8rem 2rem auto auto",
    "@media (max-width: 1024px)": {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "repeat(4, 8rem 3rem) 20px",
    },
  },
  logo: {
    alignItems: "center",
    display: "flex",
    gridColumn: "1/4",
    gridRow: "1/2",
  },
  "logo-text": {
    fontSize: "1.25rem",
    fontWeight: 700,
    letterSpacing: "0rem",
    lineHeight: "1.3125rem",
    marginLeft: "1rem",
    textTransform: "uppercase",
    width: "12ch",
  },

  "info-title": {
    gridColumn: "1/2",
    gridRow: "2/3",
    "@media (max-width: 1024px)": {
      gridColumn: "1/2",
      gridRow: "2/3",
    },
  },
  info: {
    display: "flex",
    flexDirection: "column",
    gridColumn: "1/2",
    gridRow: "3/4",
    "@media (max-width: 1024px)": {
      gridColumn: "1/2",
      gridRow: "3/4",
    },
    "@media (max-width: 414px)": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  "social-title": {
    gridColumn: "2/3",
    gridRow: "2/3",
    "@media (max-width: 1024px)": {
      gridColumn: "1/2",
      gridRow: "4/5",
    },
  },
  social: {
    display: "flex",
    flexDirection: "column",
    gridColumn: "2/3",
    gridRow: "3/4",
    "@media (max-width: 1024px)": {
      gridColumn: "1/2",
      gridRow: "5/6",
    },
  },
  "schedule-title": {
    gridColumn: "3/4",
    gridRow: "2/3",
    "@media (max-width: 1024px)": {
      gridColumn: "1/2",
      gridRow: "6/7",
    },
  },
  schedule: {
    display: "flex",
    flexDirection: "column",
    gridColumn: "3/4",
    gridRow: "3/4",
    "@media (max-width: 1024px)": {
      gridColumn: "1/2",
      gridRow: "7/8",
    },
  },

  item: {
    alignItems: "center",
    cursor: "pointer",
    display: "inline-flex",
    marginBottom: "1rem",
    "& p": {
      marginLeft: "1rem",
    },

    "& p:hover": {
      color: "#ddd",
    },
  },
  "schedule-item": {
    alignItems: "center",
    justifyContent: "space-between",
    display: "inline-flex",
    marginBottom: "1rem",
    "& p": {
      marginLeft: "1rem",
    },
    "@media (max-width: 1024px)": {
      width: "256px",
    },
  },
  copyright: {
    alignItems: "center",
    backgroundColor: "#081034",
    gridColumn: "1/4",
    gridRow: "4/5",
    display: "flex",
    marginInline: -24,
    justifyContent: "space-between",
    "@media (max-width: 1024px)": {
      gridRow: "8/9",
      flexDirection: "column-reverse",
      alignItems: "flex-start",
      padding: 16,
      marginTop: 32,
    },
    "@media (max-width: 414px)": {
      marginInline: -16,
    },
    padding: 32,
    "& p:hover": {
      color: "#ddd",
      cursor: "pointer",
    },
  },
  author: {
    color: "rgba(255, 255, 255, 0.2)",
    cursor: "pointer",
    "&:hover": {
      color: "rgba(255,255,255,0.4)",
    },
    "@media (max-width: 1024px)": {
      marginTop: 16,
    },
  },
});
