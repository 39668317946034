import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    display: "grid",
    gridGap: "2rem",
    gridTemplateColumns: "1fr 1fr",
    "@media (max-width:1024px)": {
      gridGap: "1.5rem",
      gridTemplateColumns: "1fr",
    },
  },
  title: {
    color: "#0B1648",
    fontSize: "1.25rem",
    marginBottom: "2rem",
  },
  textarea: {
    gridColumn: "1/3",
    gridRow: "3/4",
    "@media (max-width:1024px)": {
      gridColumn: "1/2",
      gridRow: "5/6",
    },
  },
});
