import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    padding: "0 24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media(max-width:414px)": {
      padding: "0 16px",
    },
  },
  title: {
    color: "#F86354",
    fontSize: "1.25rem",
    marginBottom: "32px",
  },
  grid: {
    display: "grid",
    gridGap: "24px",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    width: "100%",
    "@media(max-width:1024px)": {
      gridTemplateColumns: "1fr 1fr",
    },
    "@media(max-width:414px)": {
      gridTemplateColumns: "1fr",
      gridGap: "16px",
    },
  },

  image: {
    aspectRatio: "4/3",
    height: "auto",
    width: "100%",
    "@media (max-width: 1024px)": {
      aspectRatio: "4/3",
    },
  },
});
