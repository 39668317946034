export const images = [
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/gimnasio-claudio-monteve-4f383.appspot.com/o/images%2Fgallery-1.jpeg?alt=media&token=cb293a4d-a1b6-4f38-9155-fae30ef43a26",
    alt: "Kid getting his feet painted",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/gimnasio-claudio-monteve-4f383.appspot.com/o/images%2Fgallery-2.jpeg?alt=media&token=36ff327d-ab0f-4e6e-bafb-cd88c7602328",
    alt: "Kids playing with cookies",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/gimnasio-claudio-monteve-4f383.appspot.com/o/images%2Fgallery-3.png?alt=media&token=93ab8c76-fc98-4c99-80be-d26d97b267d8",
    alt: "Kids making pink donuts ",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/gimnasio-claudio-monteve-4f383.appspot.com/o/images%2Fgallery-4.jpeg?alt=media&token=f918e561-0a03-49f4-80e2-ea6d5d3fbf5d",
    alt: "School backyard",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/gimnasio-claudio-monteve-4f383.appspot.com/o/images%2Fgallery-5.jpg?alt=media&token=048e9392-8775-4e25-aa44-59ecd3ca4e57",
    alt: "Kid painting with red paint",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/gimnasio-claudio-monteve-4f383.appspot.com/o/images%2Fgallery-6.jpg?alt=media&token=746abee1-de56-45e5-a5e7-26faad199356",
    alt: "Kids with their faces painted at the park",
  },
];
