import { images } from "./images";
import { useStyles } from "./styles.jsx";

export const Gallery = () => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <h5 className={styles.title}>Galería</h5>
      <div className={styles.grid}>
        {images.map((item, index) => (
          <img className={styles.image} key={index} src={item.image} alt={item.alt} />
        ))}
      </div>
    </div>
  );
};
