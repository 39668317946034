import { ImportContacts, Laptop, Today } from "@material-ui/icons";

export const content = [
  {
    title: "Calendario A",
    text: "Iniciamos clases en febrero y finalizamos en noviembre. Vacaciones en Junio.",
    icon: <Today style={{ fontSize: 56, color: "AA2EE6" }} />,
    style: "primary",
  },
  {
    title: "Preescolar y básica primaria",
    text: `Pre jardín, jardín y transición. Básica primaria de primer a quinto año.`,
    icon: <ImportContacts style={{ fontSize: 56, color: "F86354" }} />,
    style: "secondary",
  },
  {
    title: "Modalidad de alternancia",
    text: "Combinamos presencialidad con aprendizaje remoto, cumpliendo protocolos de bioseguridad",
    icon: <Laptop style={{ fontSize: 56, color: "3254FB" }} />,
    style: "tertiary",
  },
];
