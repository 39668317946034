import { MyForm } from "../../components/Form";
import { Map } from "../../components/Map";
import { useStyles } from "./styles";

export const Contact = () => {
  const styles = useStyles();
  return (
    <div className={styles.grid}>
      <Map />
      <MyForm />
    </div>
  );
};
