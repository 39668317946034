import { Carousel } from "antd";
import { sliderContent } from "./sliderContent";
import "./carousel.css";
import { useStyles } from "./styles";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";

export const Slider = () => {
  const styles = useStyles();
  const history = useHistory();
  return (
    <div className={styles.container}>
      <Carousel autoplay>
        {sliderContent.map((item, index) => (
          <div key={index}>
            <div className={`${styles.slide} ${styles[item.style[0]]}`}>
              <div className={styles.content}>
                <h2 className={styles.header}>{item.header}</h2>
                <h3 className={`${styles.title} ${styles[item.style[1]]}`}>{item.title}</h3>
                <p className={styles.paragraph}>{item.paragraph}</p>
                <Button
                  onClick={() => history.push(item.push)}
                  variant="contained"
                  className={`${styles.button} ${styles[item.style[2]]}`}
                >
                  {item.button}
                </Button>
              </div>
              <img className={styles.image} src={item.image} alt={item.alt} />
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};
