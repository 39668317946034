import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  map: {
    border: 0,
    height: "600px",
    width: "100%",
  },
  title: {
    color: "#0B1648",
    fontSize: "1.25rem",
    marginBottom: "2rem",
  },
});
