import { useStyles } from "./styles";

export const Claudio = () => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div>
        <h6 className={styles.title}>¿Quién fue Claudio Monteverdi?</h6>
        <p className={styles.paragraph}>
          Claudio Monteverdi, músico y compositor Italiano del siglo XVI revolucionó la concepción
          del arte musical con el surgimiento de la ópera. Representa innovación, su movimiento
          artístico nos inspira a explorar en la música, danza, arte plástico, canto e
          interpretación musical.
        </p>
      </div>
      <img
        className={styles.img}
        src="https://firebasestorage.googleapis.com/v0/b/gimnasio-claudio-monteve-4f383.appspot.com/o/images%2Fclaudio.png?alt=media&token=f31f64f6-c35d-4990-8f4e-7337ffaab8b8"
        alt="Claudio Monteverdi animado"
      />
    </div>
  );
};
