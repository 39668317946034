export const navLinks = [
  {
    title: "Inicio",
    path: "/",
  },
  {
    title: "Sobre nosotros",
    path: "/about",
  },
  {
    title: "Proyectos",
    path: "/projects",
  },
  {
    title: "Desde casa",
    path: "/homeschool",
  },
  {
    title: "Contacto",
    path: "/contact",
  },
];
