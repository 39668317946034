import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    padding: "3rem 1.5rem",
    gridGap: "2rem",
    "@media (max-width:1024px)": {
      gridTemplateColumns: "1fr",
      padding: "3rem 1rem",
    },
  },
});
