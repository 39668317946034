import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    width: "100%",
    padding: "3rem 1.5rem",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    gridGap: "2rem",
    "@media(max-width:1024px)": {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "repeat(7, 1fr)",
    },
  },
  title: {
    color: "#0B1648",
    fontSize: "1.25rem",
    marginBottom: "1.5rem",
  },
  card: {
    color: "#fff",
    fontSize: "1.25rem",
    fontWeight: 600,
    textAlign: "center",
    padding: "2rem",
    "&:hover": {
      cursor: "pointer",
    },
  },
  content: {
    fontSize: "1.1rem",
    fontWeight: 500,
  },
  love: {
    gridColumn: "1/4",
    backgroundColor: "#F86354",
    "@media(max-width:1024px)": {
      gridColumn: "1/2",
      gridRow: "1/2",
    },
  },
  honesty: {
    backgroundColor: "#7CC3DA",
  },
  responsability: {
    backgroundColor: "#5DCDB2",
  },
  empathy: {
    backgroundColor: "#774FEA",
  },
  puntuality: {
    backgroundColor: "#FF997B",
  },
  solidarity: {
    backgroundColor: "#FAB0C6",
  },
  respect: {
    backgroundColor: "#FDB500",
  },
});
