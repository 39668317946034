export const testimonials = [
  {
    testimonial:
      "Nosotros elegimos el G.C.M. por que ofrece una educación personalizada, el colegio brinda la atención que yo deseo para mi hija.",
    author: "Familia Roa Peñuela",
  },
  {
    testimonial:
      "El colegio tiene alta calidad humana, tiene en cuenta que sus estudiantes necesitan una educación integral.",
    author: "Adriana Gómez, madre de familia.",
  },
  {
    testimonial:
      "Se tiene muy en cuenta la personalidad de los niños, se les permite ser ellos mismos.  Es un colegio receptivo y escucha a los papás.",
    author: "Marta Sepúlveda",
  },
  {
    testimonial:
      "Resalto el proceso de inclusión, el cariño, la metodología y el trabajo con niños que tiene capacidades diversas.",
    author: "Jennifer Gaitán, madre de familia",
  },
  {
    testimonial:
      "Nosotros veníamos de un modelo homeschool, y llegamos al Monteverdi.  Nos gustó la calidad humana, el buen trato de todos los integrantes de la institución, veo a los niños tranquilos y alegres, y eso nos hace sentir que estamos en el mejor lugar.",
    author: "Familia Castro Méndez",
  },
  {
    testimonial:
      "Se rescata la calidez humana, el acompañamiento de las docentes para que cada estudiante logre aprender. La utilización de diferentes estrategias para que aprendan de manera significativa estimula la creatividad y fortalece los talentos de los niños.",
    author: "Familia Barrera Peñaranda",
  },
];
