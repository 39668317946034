import { Carousel } from "antd";
import { useStyles } from "./styles.jsx";
import "../Slider/carousel.css";
import { testimonials } from "./testimonials.js";

export const Testimonials = () => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <h5 className={styles.title}>Testimonios</h5>

      <Carousel autoplay>
        {testimonials.map((item, index) => (
          <div key={index}>
            <p className={styles.testimonial}>{item.testimonial}</p>
            <p className={styles.author}>{item.author}</p>
          </div>
        ))}
      </Carousel>
    </div>
  );
};
