import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => {
  return {
    nav: {
      backgroundColor: "#0b1648",
      height: 116,
    },
    toolbar: {
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    logo: {
      alignItems: "center",
      display: "flex",
      margin: "1rem 0",
    },
    "logo-text": {
      fontSize: "0.75rem",
      fontWeight: 700,
      letterSpacing: "0rem",
      lineHeight: "0.75rem",
      marginLeft: "1rem",
      textTransform: "uppercase",
      width: "10ch",
    },
    tabs: {
      width: "90vw",
    },
    tab: {
      textTransform: "capitalize",
    },
  };
});
