import { Button } from "@material-ui/core";
import { useFormikContext } from "formik";

export const ButtonWrapper = ({ children, ...otherProps }) => {
  const { submitForm } = useFormikContext();
  const handleSubmit = () => submitForm();
  const configButton = {
    ...otherProps,
    variant: "contained",
    onClick: handleSubmit,
  };

  return (
    <Button
      {...configButton}
      style={{
        backgroundColor: "#0b1648",
        color: "#fff",
      }}
    >
      {children}
    </Button>
  );
};
