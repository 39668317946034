import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    backgroundColor: "#7CC3DA",
    padding: "3rem 20rem",
    marginTop: "5rem",
    width: "100vw",
    "@media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm)": {
      width: "calc(100vw - 16.6px)",
    },
    "@media(min-width: 414px)": {
      padding: "3rem 20rem",
    },
    "@media(max-width: 1024px)": {
      padding: "3rem 4rem",
    },
    textAlign: "center",
  },
  title: {
    color: "#0B1648",
    fontSize: "1.25rem",
  },
  testimonial: {
    color: "#fff",
    fontSize: "1rem",
    margin: "3rem 0 ",
  },
  author: {
    color: "#0B1648",
    fontWeight: "500",
    margin: "3rem 0 ",
  },
});
