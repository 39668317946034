import { useState, useEffect, useCallback } from "react";
import { useLocation, useHistory } from "react-router";
import { AppBar, Tab, Tabs, Toolbar } from "@material-ui/core";
import { useStyles } from "./styles.jsx";
import { navLinks } from "./navLinks";
import logo from "./logo.svg";

export const Navbar = () => {
  const styles = useStyles();
  const location = useLocation();
  const history = useHistory();
  const changeTab = useCallback(() => {
    let path = location.pathname;
    return path === "/"
      ? 0
      : path === "/about"
      ? 1
      : path === "/projects"
      ? 2
      : path === "/homeschool"
      ? 3
      : path === "/contact"
      ? 4
      : 5;
  }, [location.pathname]);

  const [value, setValue] = useState(changeTab);
  const handleChange = (e, newValue) => {
    setValue(newValue);
    window.scroll(0, 0);
  };

  useEffect(() => {
    setValue(changeTab);
    window.scroll(0, 0);
  }, [changeTab]);

  return (
    <AppBar className={styles["nav"]}>
      <Toolbar className={styles["toolbar"]}>
        <div className={styles["logo"]}>
          <img src={logo} alt="Escudo Gimnasio Claudio Monteverdi" />
          <h1 className={styles["logo-text"]}>Gimnasio Claudio Monteverdi</h1>
        </div>
        <Tabs
          className={styles["tabs"]}
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { background: "#fff" } }}
          variant="scrollable"
        >
          {navLinks.map((item) => (
            <Tab
              className={styles["tab"]}
              key={item.title}
              label={item.title}
              onClick={() => history.push(item.path)}
            />
          ))}
        </Tabs>
      </Toolbar>
    </AppBar>
  );
};
