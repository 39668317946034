import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    padding: "3rem 1.5rem",
    width: "100%",
    "@media (max-width:1024px)": {
      gridTemplateColumns: "1fr",
    },
  },
  card: {
    margin: "2rem 2rem",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  title: {
    fontSize: "1.25rem",
    margin: "1rem 0",
  },
  text: {
    fontSize: "1rem",
  },
  image: {
    height: "170px",
    margin: "1rem 0",
  },
});
