import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "repeat(2, 1fr)",
    padding: "3rem 1.5rem",
    width: "60%",
    "@media (max-width:1024px)": {
      gridTemplateColumns: "1fr",
      width: "100%",
    },
    margin: "0 auto",
    textAlign: "center",
  },
  options: {
    gridColumn: "1/3",
    gridRow: "1/2",
    "& h5": {
      color: "#FDB500",
      fontSize: "1.25rem",
    },
    "& p": {
      margin: "1rem 0",
      letterSpacing: "0.5px",
      lineHeight: 1.65,
      fontSize: "1rem",
    },
    "@media (max-width:1024px)": {
      gridColumn: "1/2",
      gridRow: "1/2",
    },
    marginBottom: "3rem",
  },
  intelligence: {
    "& h6": {
      color: "#F86354",
      fontSize: "1.25rem",
    },
    "& p": {
      margin: "1rem 0",
      letterSpacing: "0.5px",
      lineHeight: 1.5,
      fontSize: "1rem",
    },
  },
  hability: {
    "& h6": {
      color: "#F86354",
      fontSize: "1.25rem",
    },
    "& p": {
      margin: "1rem 0",
      letterSpacing: "0.5px",
      lineHeight: 1.5,
      fontSize: "1rem",
    },
  },
});
