import { Claudio } from "../../components/Claudio";
import { HistoryAndMission } from "../../components/HistoryAndMission";
import { Values } from "../../components/Values";

export const About = () => {
  return (
    <>
      <HistoryAndMission />
      <Values />
      <Claudio />
    </>
  );
};
