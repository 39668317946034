import { useStyles } from "./styles";

export const Options = () => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.options}>
        <h5>Opciones:</h5>
        <p>
          Queremos conocer los intereses, el enfoque de educación y expectativas de la familia en el
          proceso de aprendizaje en casa, así como también las dificultades que se han encontrado en
          el camino con el objetivo de definir y construir las herramientas para acompañar a los
          participantes del programa “At Home”.
        </p>
      </div>
      <div className={styles.intelligence}>
        <h6>Valoración de inteligencias múltiples</h6>
        <p>¿Qué aprendo mejor?</p>
        <p>
          Identificamos los niveles de las inteligencias, talentos y habilidades con el objetivo de
          desarrollar contenidos y experiencias que las fortalezcan.
        </p>
      </div>
      <div className={styles.hability}>
        <h6>Valoración de habilidades de percepción</h6>
        <p>¿Cómo aprendo mejor? </p>
        <p>
          Identificamos el estilo o la manera de captar, entender y procesar la información para
          lograr el aprendizaje de una manera natural.
        </p>
      </div>
    </div>
  );
};
