export const values = [
  {
    title: "Amor",
    content: "Fuerza que nos impulsa a hacer las cosas bien",
    styles: "love",
  },
  {
    title: "Honestidad",
    content: "Actuar y hablar con la verdad, no apropiarse de lo ajeno.",
    styles: "honesty",
  },
  {
    title: "Responsabilidad",
    content: "Ser consciente de las desiciones, aceptar las consecuencias.",
    styles: "responsability",
  },
  {
    title: "Empatía",
    content: "Ponerse en el lugar del otro, entender sus sentimientos.",
    styles: "empathy",
  },
  {
    title: "Puntualidad",
    content: "Llegar a tiempo a donde tengamos que ir.",
    styles: "puntuality",
  },
  {
    title: "Solidaridad",
    content: "Es la colaboración mutua entre las personas.",
    styles: "solidarity",
  },
  {
    title: "Respeto",
    content: "Es el buen trato hacia las personas que nos rodean",
    styles: "respect",
  },
];
