import { Info } from "../../components/Info";
import { Slider } from "../../components/Slider";
import { Gallery } from "../../components/Gallery";
import { Testimonials } from "../../components/Testimonials";

const image =
  "url(https://firebasestorage.googleapis.com/v0/b/gimnasio-claudio-monteve-4f383.appspot.com/o/images%2Fbackground.png?alt=media&token=fbc2b0cd-bfc7-4209-a8a5-2d62586737ee)";

export const Home = () => (
  <>
    <Slider />
    <div
      style={{
        backgroundImage: image,
      }}
    >
      <Info />
      <Gallery />
      <Testimonials />
    </div>
  </>
);
