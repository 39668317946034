import { useStyles } from "./styles";

export const HistoryAndMission = () => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.history}>
        <div className={styles["history-content"]}>
          <h5 className={styles.title}>Nuestra historia</h5>
          <p className={styles.paragraph}>
            Este sueño inició en el año 2002 bajo el nombre de ”El taller de los artistas”.
            Empezamos nuestro proceso de creación y legalización, obteniendo en 2008 la aprobación
            para pre-escolar bajo el nombre Gimnasio Claudio Monteverdi.{" "}
          </p>
          <p className={styles.paragraph}>
            Cinco años despues añadimos la educación básica primaria a nuestra institución, buscando
            la formación integral de los niños en un ambiente de buen trato, calidad humana y
            reconocimiento de sus cualidades individuales.
          </p>
        </div>
        <div className={styles["history-color"]}>
          <img
            className={styles["history-image"]}
            src="https://firebasestorage.googleapis.com/v0/b/gimnasio-claudio-monteve-4f383.appspot.com/o/images%2Ffigures.png?alt=media&token=f581af91-8ed2-4623-a84c-87dbd11ceed8"
            alt="Figures"
          />
        </div>
      </div>
      <div className={styles.mission}>
        <div className={styles["mission-color"]}>
          <img
            className={styles["mission-image"]}
            src="https://firebasestorage.googleapis.com/v0/b/gimnasio-claudio-monteve-4f383.appspot.com/o/images%2Ffigures2.png?alt=media&token=53740489-0daf-4770-9d16-9c396e1ffad7"
            alt="Figures"
          />
        </div>
        <div className={styles["history-content"]}>
          <h5 className={styles.title}>Nuestra misión y visión</h5>
          <p className={styles.paragraph}>
            Somos una comunidad educadora, con una proyección humanística basada en el respeto por
            la individualidad y las múltiples inteligencias, fundamentados en la pedagogía del buen
            trato mediante la cual pretendemos hacer felices a los niños de hoy para contar en el
            futuro con adultos emocionalmente fuertes, capaces de descubrir su propósito de vida.
          </p>
          <p className={styles.paragraph}>
            En el Gimnasio Claudio Monteverdi, nos proyectamos como un colegio innovador, que
            trabaja para descubrir y fortalecer los talentos propios de cada uno de sus estudiantes.
            En 2025, seremos reconocidos por haber consolidado una propuesta pedagógica que se
            adapta a las diferentes necesidades y características de los estudiantes y sus familias.
          </p>
        </div>
      </div>
    </div>
  );
};
