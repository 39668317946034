import "./styles.css";
import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";

export const Layout = ({ children }) => {
  return (
    <div className="layout">
      <header>
        <Navbar />
      </header>
      <main>{children}</main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};
