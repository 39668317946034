import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "space-around",
    padding: "48px 24px",
    "@media (max-width: 1024px)": {
      justifyContent: "space-between",
      flexDirection: "column",
      padding: "32px 16px",
    },
  },
  card: {
    fontSize: "1rem",
    padding: 8,
    "@media (max-width: 1024px)": {
      alignSelf: "center",
      margin: "16px 0",
      width: "50%",
    },
    "@media (max-width: 414px)": {
      width: "70%",
    },
  },

  title: {
    fontWeight: 500,
  },
  text: {
    marginTop: 24,
    marginBottom: 44,
    width: "27ch",
    "@media (max-width: 1024px)": {
      width: "100%",
    },
    "@media (max-width: 414px)": {
      width: "100%",
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 1024px)": {
      justifyContent: "flex-start",
    },
    "@media (max-width: 414px)": {
      justifyContent: "center",
    },
  },
  primary: {
    color: "#AA2EE6",
  },
  secondary: {
    color: "#F86354",
  },
  tertiary: {
    color: "#3254FB",
  },
});
