import { Card, CardContent, Collapse } from "@material-ui/core";
import { useState } from "react";
import { useStyles } from "./styles";
import { values } from "./values";

export const Values = () => {
  const styles = useStyles();
  const [expanded, setExpanded] = useState({});
  const handleExpanded = (id) => setExpanded({ ...expanded, [id]: !expanded[id] });
  return (
    <div className={styles.container}>
      <h5 className={styles.title}>Nuestros valores</h5>
      <div className={styles.grid}>
        {values.map((item, index) => (
          <Card
            key={index}
            className={`${styles.card} ${styles[item.styles]}`}
            onClick={() => handleExpanded(index)}
          >
            <CardContent>
              <p>{item.title}</p>
            </CardContent>
            <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
              <p className={styles.content}>{item.content}</p>
            </Collapse>
          </Card>
        ))}
      </div>
    </div>
  );
};
