import { useStyles } from "./styles";

export const Map = () => {
  const styles = useStyles();
  return (
    <div>
      <h5 className={styles.title}>Encuéntranos</h5>
      <iframe
        className={styles.map}
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15900.798033002073!2d-73.9430417!3d4.9062515!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e407686de8de8e7%3A0xc984bdbdf5b2aa70!2sCl.%202%20Sur%20%235-25%2C%20Sopo%2C%20Sop%C3%B3%2C%20Cundinamarca!5e0!3m2!1sen!2sco!4v1628612442289!5m2!1sen!2sco"
        allowFullScreen={true}
        loading="lazy"
        title="Localización del Gimnasio Claudio Monteverdi"
      ></iframe>
    </div>
  );
};
