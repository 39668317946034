import { Email, LocationOn as Location, Phone } from "@material-ui/icons";
import facebook from "./icons/facebook.svg";
import instagram from "./icons/instagram.svg";
import youtube from "./icons/youtube.svg";

export const footerInformation = [
  {
    icon: <Phone style={{ color: "#5dcdb2" }} />,
    content: "+(57) 312 479 8889",
    link: "tel:+573124798889",
  },
  {
    icon: <Email style={{ color: "#fdb500" }} />,
    content: "direccion@gimnasioclaudiomonteverdi.com",
    link: "mailto:direccion@gimnasioclaudiomonteverdi.com",
  },
  {
    icon: <Location style={{ color: "#f86354" }} />,
    content: "Calle 2 Sur # 5-25, Sopó, Colombia.",
    link: "https://www.google.com/maps/place/Cl.+2+Sur+%235-25,+Sopo,+Sopó,+Cundinamarca/@4.9062515,-73.9430417,15z/data=!4m13!1m7!3m6!1s0x8e407686de8de8e7:0xc984bdbdf5b2aa70!2sCl.+2+Sur+%235-25,+Sopo,+Sopó,+Cundinamarca!3b1!8m2!3d4.9061445!4d-73.9430661!3m4!1s0x8e407686de8de8e7:0xc984bdbdf5b2aa70!8m2!3d4.9061445!4d-73.9430661",
  },
];

export const footerSocial = [
  {
    icon: <img src={facebook} alt="Facebook icon" />,
    content: "Gimnasio Claudio Monteverdi",
    link: "https://www.facebook.com/Gimnasio-Claudio-Monteverdi-651313651594045/",
  },
  {
    icon: <img src={instagram} alt="Instagram icon" />,
    content: "@gcmonteverdi",
    link: "https://www.instagram.com/gcmonteverdi/?hl=es",
  },
  {
    icon: <img src={youtube} alt="Youtube icon" />,
    content: "Gimnasio Claudio Monteverdi",
    link: "https://www.youtube.com/channel/UC0yPJlGKEnBEitv0yZIMtIg/videos",
  },
];
export const footerSchedule = [
  {
    date: "Lun - Vie:",
    time: "8:00 am - 4:00 pm",
  },
  {
    date: "Sabados:",
    time: "9:00 am - 11:00 am",
  },
  {
    date: "Dom - Festivos:",
    time: "Cerrado",
  },
];

export const footerCopyright = [
  {
    className: "author",
    content: "Página creada por Camilo Vega © 2021",
    link: "https://github.com/camilovegag",
  },
  {
    className: "privacy",
    content: "Políticas de privacidad",
    link: "https://firebasestorage.googleapis.com/v0/b/gimnasio-claudio-monteve-4f383.appspot.com/o/docs%2Fpolicy.pdf?alt=media&token=68ec0bdd-2120-4531-9071-5d815522fbe3",
  },
];
