import { Card, CardContent } from "@material-ui/core";
import { content } from "./cardContent";
import { useStyles } from "./styles.jsx";

export const Info = () => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      {content.map((item, index) => (
        <Card className={styles.card} key={index} elevation={4}>
          <CardContent className={styles["card-content"]}>
            <p className={`${styles.title} ${styles[item.style]}`}>{item.title}</p>
            <p className={styles.text}>{item.text}</p>
            <div className={styles.icon}>{item.icon}</div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};
