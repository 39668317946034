import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    width: "100vw",
    "@media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm)": {
      width: "calc(100vw - 16.6px)",
    },
    "@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width:1024px)": {
      width: "100vw",
    },
  },
  slide: {
    backgroundColor: "#FDB500",
    display: "grid",
    gridTemplateColumns: "1fr 3fr",
    gridTemplateRows: "200px 1fr",
    color: "#fff",
    padding: "3rem 1.5rem 0",
    "@media(min-width: 1440px)": {
      fontSize: "1.25rem",
    },
    "@media(max-width: 414px)": {
      padding: "2rem 1rem",
      height: "calc(100vh - 116px)",
    },
    "@media(max-width: 360px)": {
      height: "100vh",
    },
  },
  content: {
    gridColumn: "1/3",
    gridRow: "1/2",
  },
  header: {
    margin: "0 0 1rem",
  },
  title: {
    margin: "1rem 0px",
  },
  paragraph: {
    margin: "0.5rem 0px",
  },
  button: {
    margin: "1rem 0px",
    fontWeight: "500",
  },
  primary: {
    backgroundColor: "#FDB500",
  },
  "primary-title": {
    color: "#AA2EE6",
  },
  "primary-button": {
    backgroundColor: "#AA2EE6",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#8F18C8",
    },
  },
  secondary: {
    backgroundColor: "#5DCDB2",
  },
  "secondary-title": {
    color: "#F86354",
  },
  "secondary-button": {
    backgroundColor: "#F86354",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#F74C3B",
    },
  },
  tertiary: {
    backgroundColor: "#F86354",
  },
  "tertiary-title": {
    color: "#3254FB",
  },
  "tertiary-button": {
    backgroundColor: "#3254FB",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#193FFB",
    },
  },
  image: {
    gridRow: "2/3",
    gridColumn: "2/3",
    objectFit: "cover",
    width: "100%",
    height: "100%",
    margin: "0 1.5rem",

    "@media(max-width: 1024px)": {
      gridRow: "2/3",
      gridColumn: "1/3",
      margin: "0 1.5rem",
    },
    "@media(max-width: 414px)": {
      position: "absolute",
      bottom: 0,
      height: "auto",
      width: "100vw",
      margin: "0 -1rem",
    },
  },
});
